<template>
  <Head>
    <title>M-Changa Affiliates | M-Changa Africa</title>
    <meta name="description" content="M-Changa Africa: Our Fees Explained." />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/signup'" />
    <meta property="og:title" content="M-Changa Fees | M-Changa Africa" />
    <meta
      property="og:description"
      content="M-Changa Africa: Our Fees Explained."
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="heading-block center">
                  <h2>M-Changa Fees Explained</h2>
                  <p>
                    M-Changa, your trusted platform for fast and easy
                    fundraising when it matters most.
                  </p>
                </div>
                <div>
                  <h3 class="mb-1">M-Changa fee</h3>
                  <p class="mb-2">
                    This fee ensures our platform runs smoothly, fundraisers are
                    verified and supported, and donations are processed
                    securely.
                  </p>
                  <p class="mb-2">4.25% on all donations</p>
                  <p>
                    For example, for a KES 1,000 donation, our fee would be KES
                    42.50.
                  </p>
                </div>
                <div>
                  <h3 class="mb-1">Payment processing fee</h3>
                  <p class="mb-2">
                    This fee covers the charges from Payment Providers for
                    processing your donations.
                  </p>
                  <p class="mb-0">0% for M-Pesa donations</p>
                  <p class="mb-2">
                    2.5% for Visa, MasterCard, or PayPal donations
                  </p>
                </div>

                <div>
                  <h3 class="mb-1">Withdrawal fee</h3>
                  <p class="mb-2">
                    M-Changa offers multiple withdrawal options. We pass on the
                    fees charged by banks and mobile providers.
                  </p>
                  <ol style="list-style-type: decimal" class="mb-2 ml-4">
                    <li><strong>Mobile Money:</strong></li>
                    <p>
                      <a
                        href="https://www.safaricom.co.ke/main-mpesa/m-pesa-for-you/tariffs-limits/consumer-tariffs-limits"
                        target="_blank"
                        >Standard Mobile Money</a
                      >
                      transaction fees apply.
                    </p>
                    <li><strong>Kenyan EFT Bank Transfers:</strong></li>
                    <ol style="list-style-type: circle">
                      <li>
                        KES 400 for withdrawals between KES 2,000 - 20,000
                      </li>
                      <li>
                        KES 600 for withdrawals between KES 20,001 - 50,000
                      </li>
                      <li>
                        KES 800 for withdrawals between KES 50,001 - 500,000
                      </li>
                      <li>Free for withdrawals above KES 500,000</li>
                    </ol>
                    <li class="mt-2">
                      <strong>International EFT Bank Transfers:</strong>
                    </li>
                    <p>A standard rate of $25 is charged.</p>
                  </ol>
                </div>
                <div>
                  <h3 class="mb-1">SMS fee</h3>
                  <p class="mb-2">
                    M-Changa allows fundraisers in Kenya to thank and update
                    their supporters via SMS. <br />
                    Each fundraiser receives 250 free SMS messages. Additional
                    SMS can be sent at the following rates:
                  </p>
                  <ul class="mb-2 ml-4">
                    <li>
                      KES 0.6 per SMS for an account with 2,500 plus donors
                    </li>
                    <li>
                      KES 1.0 per SMS for an account with 1,001 to 2,500 donors
                    </li>
                    <li>
                      KES 2.0 per SMS for an account with less than 1,000 donors
                    </li>
                  </ul>
                  <p>
                    M-Changa automatically sends a free SMS to thank, confirm,
                    and update each donor. These automated SMS messages are
                    always free.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import { Head } from '@vueuse/head';
import Footer from '../components/Footer/index.vue';

export default {
  setup() {
    return {};
  },
  components: {
    Head,
    Footer,
  },
  data() {},
};
</script>
